var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Xga,Yga,Zga,$ga,aha,bha,cha,NF,dha,eha,fha,gha,hha,iha,jha,kha,PF,lha;$CLJS.JF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Xga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.KF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Yga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Zga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.LF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$ga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.MF=new $CLJS.M(null,"display-info","display-info",-816930907);aha=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);bha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
cha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);NF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);dha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);eha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);fha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);gha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
hha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);iha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);jha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.OF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);kha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);PF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.QF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);lha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.yE($CLJS.Rw,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null)],null)]));$CLJS.yE($CLJS.kF,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null)],null)]));
$CLJS.wE($CLJS.dF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));$CLJS.wE($CLJS.OE,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)]));$CLJS.wE($CLJS.sF,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)]));
$CLJS.wE($CLJS.tj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)],null)]));$CLJS.pE($CLJS.tj,$CLJS.bE);$CLJS.wE($CLJS.wF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));$CLJS.pE($CLJS.wF,$CLJS.bE);$CLJS.wE($CLJS.ni,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)],null)]));$CLJS.pE($CLJS.ni,$CLJS.bE);
$CLJS.Y(NF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.ts,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.wE($CLJS.VE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NF],null)]));$CLJS.pE($CLJS.VE,$CLJS.bE);
$CLJS.wE($CLJS.qF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)]));$CLJS.wE($CLJS.xF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));$CLJS.wE($CLJS.vF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));
$CLJS.wE($CLJS.EF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));$CLJS.pE($CLJS.vF,$CLJS.bE);$CLJS.pE($CLJS.EF,$CLJS.bE);$CLJS.wE($CLJS.CF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)]));$CLJS.pE($CLJS.CF,$CLJS.bE);
$CLJS.wE($CLJS.PE,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));$CLJS.Y(PF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.er,kha,iha,bha,Yga,Xga,aha,cha,dha,eha,hha,fha,lha,jha,Zga,gha,$CLJS.al],null));$CLJS.Y($CLJS.KF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.ni,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PF],null)],null));
$CLJS.RF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.cF,$CLJS.Rw,$CLJS.LF,!1,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Count of rows"),$CLJS.UE,$CLJS.JD("Count"),$CLJS.Hs,$CLJS.JD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.vF,$CLJS.JF,$CLJS.qj,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Sum of ..."),$CLJS.UE,$CLJS.JD("Sum"),$CLJS.Hs,$CLJS.JD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.dF,$CLJS.JF,$CLJS.qj,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Average of ..."),$CLJS.UE,$CLJS.JD("Average"),$CLJS.Hs,$CLJS.JD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.wF,$CLJS.JF,$CLJS.qj,$CLJS.LF,!0,$CLJS.OF,$CLJS.fF,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Median of ..."),$CLJS.UE,$CLJS.JD("Median"),$CLJS.Hs,$CLJS.JD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.OE,$CLJS.JF,$CLJS.Yh,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Number of distinct values of ..."),$CLJS.UE,$CLJS.JD("Distinct values"),$CLJS.Hs,$CLJS.JD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.EF,$CLJS.JF,$CLJS.qj,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Cumulative sum of ..."),
$CLJS.UE,$CLJS.JD("Sum"),$CLJS.Hs,$CLJS.JD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.cF,$CLJS.kF,$CLJS.LF,!1,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Cumulative count of rows"),$CLJS.UE,$CLJS.JD("Count"),$CLJS.Hs,$CLJS.JD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.xF,$CLJS.JF,$CLJS.qj,$CLJS.LF,
!0,$CLJS.OF,$CLJS.tF,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Standard deviation of ..."),$CLJS.UE,$CLJS.JD("SD"),$CLJS.Hs,$CLJS.JD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.ni,$CLJS.JF,$CLJS.Bi,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Minimum of ..."),$CLJS.UE,$CLJS.JD("Min"),$CLJS.Hs,$CLJS.JD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.tj,$CLJS.JF,$CLJS.Bi,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.JD("Maximum of ..."),$CLJS.UE,$CLJS.JD("Max"),$CLJS.Hs,$CLJS.JD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y($ga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.QF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.cf.h($CLJS.cF),$CLJS.RF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Ai],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,$CLJS.ur],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.OF,$CLJS.Ai],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.id],null)],null));
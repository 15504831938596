var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var mha,nha,oha,pha,qha,rha,sha;$CLJS.SF=new $CLJS.M(null,"second","second",-444702010);$CLJS.TF=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.UF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.VF=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.WF=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
mha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);nha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.XF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.YF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.ZF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);oha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
pha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);qha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);rha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.$F=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.aG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.bG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
sha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var cG=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.xk,$CLJS.Ck,$CLJS.Bk,$CLJS.sk,$CLJS.Hk,$CLJS.wk,$CLJS.rk,$CLJS.WF],null),dG=$CLJS.Wg(cG),tha,uha,vha;$CLJS.Y(qha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date extraction unit"],null)],null),dG));var eG=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kk,$CLJS.Oi,$CLJS.Ui,$CLJS.bi,$CLJS.rk],null),fG=$CLJS.Wg(eG);
$CLJS.Y(pha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date truncation unit"],null)],null),fG));$CLJS.gG=$CLJS.Wf.j($CLJS.xf,$CLJS.nk.o(),$CLJS.bf.g(eG,cG));tha=$CLJS.Wg($CLJS.gG);$CLJS.Y($CLJS.ZF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date bucketing unit"],null)],null),tha));var hG=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,$CLJS.Ak,$CLJS.uk],null),iG=$CLJS.Wg(hG);
$CLJS.Y(oha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time extraction unit"],null)],null),iG));var jG=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.VF,$CLJS.SF,$CLJS.zk,$CLJS.Ik],null),kG=$CLJS.Wg(jG);$CLJS.Y(sha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time truncation unit"],null)],null),kG));$CLJS.lG=$CLJS.Wf.j($CLJS.xf,$CLJS.nk.o(),$CLJS.bf.g(jG,hG));uha=$CLJS.Wg($CLJS.lG);
$CLJS.Y(nha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time bucketing unit"],null)],null),uha));$CLJS.mG=$CLJS.Wf.j($CLJS.xf,$CLJS.nk.o(),$CLJS.bf.l(jG,eG,$CLJS.H([hG,cG])));vha=$CLJS.Wg($CLJS.mG);$CLJS.Y($CLJS.UF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime bucketing unit"],null)],null),$CLJS.mG));var wha=$CLJS.Yd.g(vha,$CLJS.Oh);
$CLJS.Y($CLJS.aG,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid temporal bucketing unit"],null)],null),wha));$CLJS.nG=$CLJS.Ws.g(fG,kG);$CLJS.Y($CLJS.XF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime truncation unit"],null)],null),$CLJS.nG));$CLJS.oG=$CLJS.Ws.g(dG,iG);$CLJS.Y(mha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime extraction unit"],null)],null),$CLJS.oG));
var pG=$CLJS.Yd.g(fG,$CLJS.rk);$CLJS.Y($CLJS.bG,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date interval unit"],null)],null),pG));$CLJS.Y($CLJS.YF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time interval unit"],null)],null),kG));var xha=$CLJS.Ws.g(pG,kG);$CLJS.Y($CLJS.dD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime interval unit"],null)],null),xha));
$CLJS.Y(rha,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.$F],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.aG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.ur],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var KD,PD,Pga,Qga,Rga,Sga,Tga,Uga;
$CLJS.ID=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.ez([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.JD=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.gD(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};KD=function(a){return $CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M};
$CLJS.MD=function(a){var b=function(){var c=KD(a);return c?(c=$CLJS.sd($CLJS.dd(a)))?(c=$CLJS.ki.h($CLJS.dd(a)),$CLJS.n(c)?c:$CLJS.tA.h($CLJS.dd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.LD.h(a)};
PD=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,2,[$CLJS.ts,"valid MBQL clause",$CLJS.Es,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.$i);return["invalid MBQL clause: ",$CLJS.xh.l($CLJS.H([c]))].join("")}],null),$CLJS.Oe(KD)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ND],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,b],null),function(c){c=$CLJS.MD(c);
return OD(c,a)}],null)],null)};$CLJS.QD=new $CLJS.M(null,"right-join","right-join",-56349359);Pga=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Qga=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Rga=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.RD=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.SD=new $CLJS.M(null,"expr","expr",745722291);$CLJS.TD=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.UD=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.VD=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.WD=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.XD=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.YD=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.ZD=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Sga=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.$D=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.aE=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.bE=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.cE=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Tga=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
Uga=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.dE=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.eE=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.ND=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.fE=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.gE=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.hE=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.iE=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.LD=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ki,$CLJS.hB],null),$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.YA(f);return $CLJS.E.g(k,$CLJS.sA)?$CLJS.Ya(f):k},e,a,b,c,d)}();
$CLJS.Y(Qga,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.YD],null),$CLJS.AD],null));$CLJS.LD.m(null,$CLJS.Oh,function(a){throw $CLJS.Uh($CLJS.gD("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Tga,$CLJS.xh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.SD,a],null));});$CLJS.LD.m(null,$CLJS.bE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.MD(a)});
var OD=function OD(a,b){return $CLJS.qd(a)?$CLJS.Me(function(d){return OD.g?OD.g(d,b):OD.call(null,d,b)},a):$CLJS.qd(b)?$CLJS.Me(function(d){return OD.g?OD.g(a,d):OD.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.YD)?!0:$CLJS.Dz(a,b)};$CLJS.Y($CLJS.WD,PD($CLJS.ej,"expression returning a boolean"));$CLJS.Y($CLJS.fE,PD($CLJS.pj,"expression returning a string"));$CLJS.Y($CLJS.iE,PD($CLJS.Ti,"expression returning an integer"));$CLJS.Y(Rga,PD($CLJS.MC,"expression returning a non-integer real number"));
$CLJS.Y($CLJS.hE,PD($CLJS.gj,"expression returning a number"));$CLJS.Y(Sga,PD($CLJS.vk,"expression returning a date"));$CLJS.Y(Uga,PD($CLJS.Lk,"expression returning a time"));$CLJS.Y(Pga,PD($CLJS.Zi,"expression returning a date time"));$CLJS.Y($CLJS.UD,PD($CLJS.lj,"expression returning a date, time, or date time"));$CLJS.jE=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.gj,null,$CLJS.pj,null,$CLJS.lj,null,$CLJS.ej,null],null),null);$CLJS.Y($CLJS.cE,PD($CLJS.jE,"an expression that can be compared with :\x3e or :\x3c"));
var Vga=new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.CB,null,$CLJS.gj,null,$CLJS.pj,null,$CLJS.gC,null,$CLJS.lj,null,$CLJS.Xi,null,$CLJS.ej,null,$CLJS.KC,null],null),null);$CLJS.Y($CLJS.eE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),PD($CLJS.gC,"expression returning a BSONID")],null));$CLJS.Y($CLJS.aE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,PD(Vga,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.Y($CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,PD($CLJS.Xi,"any type of expression")],null));
$CLJS.Y($CLJS.TD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.ni,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tq,$CLJS.Yh,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pD,$CLJS.nj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Yh],null)],null)],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Cy=function(){},Dy=function(a){return $CLJS.J.g($CLJS.sx,a).value},wfa=function(a){var b=Dy(a);return $CLJS.n(function(){var c=Dy($CLJS.Jx);return $CLJS.Qj.g?$CLJS.Qj.g(c,b):$CLJS.Qj.call(null,c,b)}())?$CLJS.ny:$CLJS.n(function(){var c=Dy($CLJS.Ix);return $CLJS.Qj.g?$CLJS.Qj.g(c,b):$CLJS.Qj.call(null,c,b)}())?$CLJS.xy:$CLJS.n(function(){var c=Dy($CLJS.Gx);return $CLJS.Qj.g?$CLJS.Qj.g(c,b):$CLJS.Qj.call(null,c,b)}())?$CLJS.qy:$CLJS.n(function(){var c=Dy($CLJS.Ax);return $CLJS.Qj.g?
$CLJS.Qj.g(c,b):$CLJS.Qj.call(null,c,b)}())?$CLJS.wy:$CLJS.n(function(){var c=Dy($CLJS.Hx);return $CLJS.Qj.g?$CLJS.Qj.g(c,b):$CLJS.Qj.call(null,c,b)}())?$CLJS.yy:$CLJS.n(function(){var c=Dy($CLJS.Dx);return $CLJS.Qj.g?$CLJS.Qj.g(c,b):$CLJS.Qj.call(null,c,b)}())?$CLJS.ty:$CLJS.n(function(){var c=Dy($CLJS.xx);return $CLJS.Qj.g?$CLJS.Qj.g(c,b):$CLJS.Qj.call(null,c,b)}())?$CLJS.uy:$CLJS.vy},Ey=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Fy=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.Yd.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.By,c))].join(""),$CLJS.H(["color:black"]))],null)},Gy=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.Yd.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.By,$CLJS.zy)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.By,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},xfa=function(a){function b(c,d){return d>=c}a=Dy(a);if(b(Dy($CLJS.Jx),a))return"error";if(b(Dy($CLJS.Ix),a))return"warn";if(b(Dy($CLJS.Gx),a))return"info";b(Dy($CLJS.Ax),a);return"log"},Hy=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.yx),e=$CLJS.J.g(c,$CLJS.os);b=$CLJS.J.g(c,$CLJS.Dj);e=xfa(e);e=$CLJS.Aa(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Ne.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Iy=function Iy(a,b){for(;;){if($CLJS.E.g($CLJS.my,b))return Fy(a,", ",$CLJS.vy);if($CLJS.E.g($CLJS.oy,b))return Ey(a);if(b instanceof $CLJS.M)return Fy(a,b,$CLJS.qy);if(b instanceof $CLJS.r)return Fy(a,b,$CLJS.wy);if("string"===typeof b)return Fy(a,$CLJS.xh.l($CLJS.H([b])),$CLJS.py);if($CLJS.uf(b)){var d=Ey(function(){var f=a,k=$CLJS.Bb(b);return Iy.g?Iy.g(f,k):Iy.call(null,f,k)}()),e=$CLJS.Cb(b);
return Iy.g?Iy.g(d,e):Iy.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.yg)return d=a,d=Fy(d,"{",$CLJS.Ay),d=$CLJS.bb(Iy,d,$CLJS.af($CLJS.my,b)),Fy(d,"}",$CLJS.Ay);if($CLJS.sd(b))return d=a,d=Fy(d,["#",$CLJS.p.h(function(){var f=$CLJS.Ya(b),k=f.name;return $CLJS.od(k)?$CLJS.xh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.ry),d=Fy(d,"{",$CLJS.Ay),d=$CLJS.bb(Iy,d,$CLJS.af($CLJS.my,b)),Fy(d,"}",$CLJS.Ay);if($CLJS.qd(b))return d=a,d=Fy(d,"#{",$CLJS.Ay),d=$CLJS.bb(Iy,d,$CLJS.af($CLJS.oy,b)),Fy(d,
"}",$CLJS.Ay);if($CLJS.ud(b))return d=a,d=Fy(d,"[",$CLJS.Ay),d=$CLJS.bb(Iy,d,$CLJS.af($CLJS.oy,b)),Fy(d,"]",$CLJS.Ay);if(b instanceof $CLJS.Lf)d=Fy(a,"#queue ",$CLJS.ry),e=$CLJS.Wf.g($CLJS.xf,b),a=d,b=e;else{if($CLJS.yd(b))return d=a,d=Fy(d,"(",$CLJS.ry),d=$CLJS.bb(Iy,d,$CLJS.af($CLJS.oy,b)),Fy(d,")",$CLJS.ry);if(null!=b?b.I&16384||$CLJS.yc===b.bj||(b.I?0:$CLJS.Xa(Cy,b)):$CLJS.Xa(Cy,b))d=Fy(a,"#atom ",$CLJS.ry),e=$CLJS.q(b),a=d,b=e;else if($CLJS.Qh(b))d=Fy(a,"#uuid ",$CLJS.ry),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Ua(b))d=Fy(a,"#js ",$CLJS.ry),e=$CLJS.bb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.mh.h(m),$CLJS.Aa(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Qa(b))d=Fy(a,"#js ",$CLJS.ry),e=$CLJS.Wf.g($CLJS.xf,b),a=d,b=e;else return Fy(a,$CLJS.xh.l($CLJS.H([b])),$CLJS.sy)}}};$CLJS.yfa=Hy(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.os);var b=$CLJS.J.g(a,$CLJS.yx),c=$CLJS.J.g(a,$CLJS.ui);$CLJS.J.g(a,$CLJS.Dj);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.zfa=Hy(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.os);a=$CLJS.J.g(b,$CLJS.yx);var d=$CLJS.J.g(b,$CLJS.ui);$CLJS.J.g(b,$CLJS.Dj);b=wfa(c);d=Iy(Ey(Gy(Gy(Gy(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.xf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ae(a,d)});
$CLJS.Afa=Hy(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.os);var b=$CLJS.J.g(a,$CLJS.yx),c=$CLJS.J.g(a,$CLJS.ui);$CLJS.J.g(a,$CLJS.Dj);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.xh.l($CLJS.H([c]))],null)});